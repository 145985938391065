const TooltipsOne = [
  {
    selector: '.tooltipOne',
    title: 'Comparado con la industria',
    description:
      '<span>Este promedio se calcula comparando\n' +
      'el consumo de energía de tu empresa\n' +
      'con los datos reportados en el Balance\n' +
      'Nacional de Energía. <span style="color: #5271FF; font-weight: bold">Esto te permite\n' +
      'evaluar cómo se posiciona tu empresa\n' +
      'en relación con el promedio nacional.</span></span>',
    position: 'right'
  },
  {
    selector: '.tooltipTwo',
    title: 'Comparado con tu objetivo',
    description:
      '<span>Una vez que establezcas tu objetivo\n' +
      'de reducción de emisiones, podrás\n' +
      'visualizar aquí tu progreso. <span style="color: #5271FF; font-weight: bold">Este\n' +
      'gráfico mostrará tu avance hacia\n' +
      'el cumplimiento de las metas\n' +
      'establecidas.</span></span>',
    position: 'right'
  },
  {
    selector: '.tooltipThree',
    title: 'Consumo energético',
    description:
      '<span>Este indicador muestra el porcentaje de\n' +
      'reducción de emisiones respecto a años\n' +
      'anteriores. <span style="color: #5271FF; font-weight: bold">En el primer año de medición,\n' +
      'este valor será cero, ya que no habrá un\n' +
      'año previo para comparar</span></span>',
    position: 'right'
  },
  {
    selector: '.tooltipFour',
    title: 'Compensación',
    description:
      '<span>Si tu empresa decide\n' +
      'compensar sus emisiones,\n' +
      'aquí se verán las <span style="color: #5271FF; font-weight: bold">toneladas\n' +
      'de dioxido de carbono\n' +
      'compensadas.</span></span>',
    position: 'top-left'
  },
  {
    selector: '.tooltipFive',
    title: 'Emisiones a través del tiempo',
    description:
      '<span>Aquí puedes visualizar la evolución\n' +
      'de la huella de carbono de tu\n' +
      'empresa a lo largo del tiempo.\n' +
      '<span style="color: #5271FF; font-weight: bold">Esta herramienta te ayudará a\n' +
      'monitorear cómo cambian tus\n' +
      'emisiones con las diferentes\n' +
      'acciones implementadas.</span></span>',
    position: 'right'
  },
  {
    selector: '.tooltipSix',
    title: 'Medidas de reducción',
    description:
      '<span>Aquí encontrarás una lista de medidas de\n' +
      'reducción de emisiones que puedes\n' +
      'aplicar en tu empresa. <span style="color: #5271FF; font-weight: bold">Estas medidas\n' +
      'están diseñadas para alcanzar los\n' +
      'objetivos establecidos.</span></span>',
    position: 'bottom'
  },

  {
    selector: '.tooltipSeven',
    title: 'Huella de carbono por instalación',
    description:
      '<span>En esta gráfica podrás observar\n' +
      'cómo se comporta la generación de emisiones\n' +
      '<span style="color: #5271FF; font-weight: bold">en las diferentes instalaciones de la empresa\n' +
      'y comparar la huella de carbono entre las mismas' +
      '.</span></span>',
    position: 'right'
  },

  {
    selector: '.tooltipEight',
    title: 'Huella de carbono por año',
    description:
      '<span>En esta gráfica podrás observar\n' +
      'cómo se comporta la generación de emisiones\n' +
      '<span style="color: #5271FF; font-weight: bold">dependiendo del alcance a través de los años\n' +
      'y detectar patrones en generación/reducción de emisiones' +
      '.</span></span>',
    position: 'bottom'
  }
];
const TooltipsTwo = [
  {
    selector: '.tooltipOne',
    title: 'Alcance 1',
    description: '<span style="text-justify: auto">Son las emisiones directas <br>' + 'de fuentes propias controladas por la empresa.</span>',
    position: 'right'
  },
  {
    selector: '.tooltipTwo',
    title: 'Alcance 2',
    description:
      '<span>Son las\n' +
      'emisiones indirectas\n' +
      'por la generación de\n' +
      'electricidad, calor o\n' +
      'vapor comprado por\n' +
      'la empresa.</span>',
    position: 'right'
  },
  {
    selector: '.tooltipThree',
    title: 'Alcance 3',
    description:
      '<span>son las\n' +
      'emisiones indirectas\n' +
      'procedentes de\n' +
      'fuentes que no son\n' +
      'propiedad de una\n' +
      'empresa ni están\n' +
      'directamente\n' +
      'controladas por ella,\n' +
      'pero que están\n' +
      'relacionadas con sus\n' +
      'actividades.</span>',
    position: 'bottom'
  },
  {
    selector: '.tooltipFour',
    title: 'Huella de carbono',
    description:
      '<span>Después de\n' +
      'seleccionar la\n' +
      'categoría y la fecha\n' +
      'de interés, este\n' +
      'gráfico mostrará las\n' +
      'toneladas de CO2e\n' +
      'correspondientes a\n' +
      'esa categoría\n' +
      'específica.</span>',
    position: 'right'
  },
  {
    selector: '.tooltipFive',
    title: 'Ruta cero emisiones',
    description:
      '<span>La ruta cero emisiones dice la máxima cantidad <br>' +
      'de toneladas que se pueden producri por año para alcanzar' +
      ' cierto objetivo. Hay 3 opciones:' +
      '<ul> <li style="font-size: 14px">1. La línea naranja: para lograr cero emisiones en el 2030</li>' +
      '<li style="font-size: 14px">2. La línea roja: para lograr cero emisiones en el 2050</li>' +
      '<li style="font-size: 14px">3. La curva azul: lograr la mínima reducción, es decir 4.2% o 6% por año.</li></ul>' +
      '</span>',
    position: 'bottom'
  },
  {
    selector: '.tooltipSix',
    title: 'Agregar',
    description:
      '<span>Una vez que descargues la plantilla, completa las columnas\n' +
      'requeridas, guárdala y súbela nuevamente a la plataforma.\n' +
      '    Si se marca algún error, revisa en la opción <strong style="color: #5271ff">Agregar</strong> \n' +
      '    para ver las opciones permitidas y corregir la información.</span>',
    position: 'top'
  }
];
//@click="$refs.tooltipGuide.start(0)"
export { TooltipsOne, TooltipsTwo };
